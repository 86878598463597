import axios from "axios";
import { Message, MessageBox } from "element-ui";
import { getBaseUrl } from "@/utils/tools";
// 引入加解密方法
import { encryptFun, decrypt } from "@/utils/encrypt";
let url = getBaseUrl();
export const baseUrl = url[url.length - 1] == "/" ? url : url + "/";
// export const baseUrl = "http://dev.ikukan.com/";//正式环境
// export const baseUrl = "http://desk-platform.outside.misetv.com/"; //测试地址
// export const baseUrl = "http://dev.deskout.misetv.com/"; //开发地址
// export const baseUrl = "http://192.168.3.41:80/"; //开发地址

const service = axios.create({
  baseURL: baseUrl,
  timeout: 20000,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
  },
});

//请求拦截
service.interceptors.request.use(
  (config) => {
    // config.headers.muksha = true;
    //在请求前做点什么
    let token = getToken();
    if (token) {
      config.headers.Authorization = token;
    }
    // 如果是get请求，url中添加时间戳清除缓存
    if (config.method == "get") {
      if (config.url.indexOf("?") > -1) {
        config.url = config.url + "&randomTime=" + new Date().getTime();
      } else {
        config.url = config.url + "?randomTime=" + new Date().getTime();
      }
    }
    // 在参数传递至后端前，将参数进行AES加密
    if (config.data) {
      // 加密之前先转换为JSON字符串
      const data = JSON.stringify(config.data);
      config.data = {
        data: encryptFun(data),
      };
    }

    return config;
  },

  (error) => {
    console.log("请求拦截=>", error);
    return error;
  }
);

//响应拦截
service.interceptors.response.use(
  (response) => {
    let flag = "x-refresh-token" in response.headers;
    if (flag) {
      setToken(response.headers["x-refresh-token"]);
    }
    const responseData = response.data || {};
    // 根据返回结果分为两种处理方式： 文件流 / 其它数据
    // 文件流返回不进行加密处理，所以这里不需要进行解密，正常解析文件流即可
    if (typeof responseData === "object") {
      if (
        response.headers["content-type"] ===
        "application/force-download;charset=utf-8"
      ) {
        const fileName = decodeURI(
          response.headers["content-disposition"].split("filename=")[1]
        );
        return Promise.resolve({
          data: responseData,
          fileName: fileName,
          type: response.headers["content-type"],
        });
      } else {
        let dataRes = response.data;
        // 对返回值为空特殊处理允许通过
        if (dataRes.code !== 200 && dataRes?.code) {
          onError(dataRes.msg);
        } else {
          return responseData;
        }
      }
    } else {
      // 除文件流之外，其它数据在后端统一进行了加密，这里需要对返回数据进行解密
      let resData = responseData?.length
        ? JSON.parse(decrypt(responseData))
        : {};
      // let resData = response.data;
      const { code, msg } = resData;

      if (code && code !== 200) {
        onError(msg);
        return false;
      } else {
        return resData || {};
      }
    }
  },
  (error) => {
    if (error?.code == "ECONNABORTED") {
      onError("网络请求超时");
    }
    const message =
      (error.response && error.response.data.msg) || error.message;
    onError(message);
    return Promise.reject(error);
  }
);

function onError(message) {
  Message({
    type: "error",
    message: message,
  });
}

export default service;
