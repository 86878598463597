<template>
    <div class="agree">
        <iframe style="width:calc(100vw - 200px);height:calc(100vh - 100px)"
            src="https://ytplayer.ikukan.com/xy.html" />
    </div>
</template>
<script>
export default {
    methods: {
    }
}
</script>
<style lang="scss" scoped>
.agree {
    padding: 10px 70px 0;
}
</style>