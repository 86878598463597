<template>
  <div id="app">
    <div style=" color: #333;margin: 20px 70px 0px;color: #409EFF;"
      v-if="type == 'agree' && !this.$route?.query?.agree">
      <span @click="type = 'list'" style="cursor: pointer;">
        << 返回 </span>
    </div>
    <index ref="index" v-if="type == 'index'" @goBack="goBack" />
    <list ref="list" v-if="type == 'list'" @goBack="goBack" />
    <agree ref="agree" v-if="type == 'agree'" @goBack="goBack" />
  </div>
</template>
<script>
import { RouterView } from 'vue-router';
import index from './views/index'
import list from './views/list'
import { getBaseUrl } from '@/utils/tools'
import agree from './views/agree'
export default {
  components: { index, list, agree },
  data() {
    return {
      show: false,
      type: ''
    }
  },

  created() {
    const VUE_APP_VERSION = require('../package.json').version
    console.log(`当前版本：${VUE_APP_VERSION}`)
  },
  mounted() {
    this.$nextTick(() => {
      this.init()

    })
  },
  methods: {
    // 判断是都从认证页面进来
    init(sign) {
      if (this.$route?.query?.videoId) {
        // 如果路径中存在影片id说明从客户端页面进来，将影片信息存储到绘画存储中，以便用户从登录页回来进行影片播放
        sessionStorage.setItem('info', JSON.stringify(this.$route.query))
      }
      let fragmentString = location.hash.substring(2);
      let params = {};
      let regex = /([^&=]+)=([^&]*)/g,
        m;
      while ((m = regex.exec(fragmentString))) {
        params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
      }
      console.log(params, Object.keys(params).length > 0, params["state"])
      if (Object.keys(params).length > 0 && params["state"]) {
        if (params["state"] == localStorage.getItem("state")) {
          localStorage.setItem("oauth2-test-params", JSON.stringify(params));
          let time = new Date().getTime()
          localStorage.setItem("time", time);
          this.trySampleRequest();
        } else {
          this.oauth2SignIn()
        }
      } else {
        // 如果客户端是为登录状态，去登录
        if (this.$route?.query?.videoId || sign) {
          if (this.$route?.query?.loginStatus == 'false') {
            localStorage.removeItem("oauth2-test-params");
            this.oauth2SignIn()
          } else {
            let oauth = localStorage.getItem("oauth2-test-params") || "";
            console.log(oauth, 'msg')
            if (oauth?.length > 0) {
              let now = new Date().getTime() * 1
              let time = localStorage.getItem('time') || now
              // 如果认证时间超过三天，则重新认证,否则直接展示视频播放
              if (now - time * 1 > 3 * 24 * 60 * 60 * 1000) {
                this.oauth2SignIn()
              } else {
                this.trySampleRequest()
              }
            } else {
              this.oauth2SignIn()
            }
          }
        } else {
          this.type = 'list'
          this.$nextTick(() => {
            this.$refs.list.init()

          })
        }
        // this.trySampleRequest()
      }

    },
    // 如果本地存储有认证信息，则掉接口判断是否失效，如果失效则重新认证，未失效则直接展示视频播放
    trySampleRequest() {
      let params = JSON.parse(localStorage.getItem("oauth2-test-params"));
      let that = this
      // if (params && params["access_token"]) {
      //   const xhr = new XMLHttpRequest();
      //   xhr.open(
      //     "GET",
      //     "https://youtube.googleapis.com/youtube/v3/playlists?part=snippet,contentDetails&mine=true&" +
      //     "access_token=" +
      //     params["access_token"]
      //   );
      //   xhr.onreadystatechange = function (e) {
      //     if (xhr.readyState === 4 && xhr.status === 200) {
      // 告诉客户端用户是否授权登录
      if (window?.Android) {
        window.Android?.loginSuccess()
      }
      let infos = sessionStorage.getItem('info') || ''
      that.type = infos?.length > 0 ? 'index' : 'list'
      // that.type = 'index'
      that.$nextTick(() => {
        that.type == 'index' ? that.$refs.index.init() : that.$refs.list.init()
        // that.$refs.index.init()
      })
      //     } else if (xhr.readyState === 4 && xhr.status === 401) {
      //       that.oauth2SignIn();
      //     }
      //   };
      //   xhr.send(null);
      // } else {
      //   localStorage.removeItem("state");
      //   that.oauth2SignIn();
      // }
    },
    oauth2SignIn() {
      if (window?.Android) {
        window.Android?.loginFail()
      }
      let state = this.generateCryptoRandomState();
      localStorage.setItem("state", state);
      const oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";
      const form = document.createElement("form");
      form.setAttribute("method", "GET");
      form.setAttribute("action", oauth2Endpoint);
      // 正式
      // "client_id":"512780092944-804og6qlhtcltem3ebu9ckslja0f168e.apps.googleusercontent.com",
      // "auth_uri":"https://accounts.google.com/o/oauth2/auth",
      // redirect_uri: "https://ytplayer.ikukan.com/",
      // 测试
      // client_id:
      //     "572596068692-lc9ft5rc5joup5vis7hhanf4ige87ksh.apps.googleusercontent.com",
      //   redirect_uri: "https://youtubeplayer.ikukan.com/",
      let params = {
        client_id:
          "512780092944-804og6qlhtcltem3ebu9ckslja0f168e.apps.googleusercontent.com",
        redirect_uri: getBaseUrl(),
        scope: "https://www.googleapis.com/auth/youtube.readonly",
        state: state,
        include_granted_scopes: "true",
        response_type: "token",
      };
      // for (let p in params) {
      //   let input = document.createElement("input");
      //   input.setAttribute("type", "hidden");
      //   input.setAttribute("name", p);
      //   input.setAttribute("value", params[p]);
      //   form.appendChild(input);
      // }
      // document.body.appendChild(form);
      // form.submit();
    },
    generateCryptoRandomState() {
      const randomValues = new Uint32Array(2);
      window.crypto.getRandomValues(randomValues);
      const utf8Encoder = new TextEncoder();
      const utf8Array = utf8Encoder.encode(
        String.fromCharCode.apply(null, randomValues)
      );
      return btoa(String.fromCharCode.apply(null, utf8Array))
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
    },
    revokeAccess() {
      // let data = JSON.parse(localStorage.getItem("oauth2-test-params"));
      // if (data?.access_token) {
      //   // Google's OAuth 2.0 endpoint for revoking access tokens.
      //   var revokeTokenEndpoint = "https://oauth2.googleapis.com/revoke";

      //   // Create <form> element to use to POST data to the OAuth 2.0 endpoint.
      //   var form = document.createElement("form");
      //   form.setAttribute("method", "post");
      //   form.setAttribute("action", revokeTokenEndpoint);

      //   // Add access token to the form so it is set as value of 'token' parameter.
      //   // This corresponds to the sample curl request, where the URL is:
      //   //      https://oauth2.googleapis.com/revoke?token={token}
      //   var tokenField = document.createElement("input");
      //   tokenField.setAttribute("type", "hidden");
      //   tokenField.setAttribute("name", "token");
      //   tokenField.setAttribute("value", data.access_token);
      //   form.appendChild(tokenField);
      //   // Add form to page and submit it to actually revoke the token.
      //   document.body.appendChild(form);
      //   form.submit();
      // }
      // localStorage.removeItem("oauth2-test-params");
      // localStorage.removeItem("state");
    },
    goBack(type, val) {
      this.type = type
      this.$nextTick(() => {
        if (type == 'index') {
          this.init(true)
          this.$refs.index.init(true, val)
        } else if (type == 'list') {
          sessionStorage.removeItem('info')
          this.$refs.list.init()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
