<template>
    <div class="list_con">
        <div style="display: flex;align-items: center;font-size:14px;">
            <p>酷看电影合集</p>
            <p @click="active = 1"
                :style="{ lineHeight: '17px', margin: '0 20px', color: active == 1 ? '#409EFF' : '#333', borderBottom: active == 1 ? '1px solid #409EFF' : '2px solid #fff', cursor: 'pointer' }">
                酷看介绍</p>
            <p :style="{ lineHeight: '17px', color: active == 0 ? '#409EFF' : '#333', borderBottom: active == 0 ? '1px solid #409EFF' : '2px solid #fff', cursor: 'pointer' }"
                @click="active = 0">影片合集</p>
        </div>
        <div v-if="active == 0" style="width:100%;">
            <div class=" table" style="margin-bottom:70px;height:calc(100% - 100px);overflow-y: auto;">
                <el-table :data="list" ref="table" style="width: 100%" transition="none"
                    :header-cell-style="{ background: '#FAFAFA', textAlign: 'center' }"
                    :cell-style="{ 'text-align': 'center' }" header-row-class-name="table_header"
                    tooltip-effect="youtubeTooltip">
                    <el-table-column label="影片名称" prop="title" min-width="100px" />
                    <el-table-column label="影片图片" width="160px">
                        <template v-slot="scope">
                            <div
                                style="width: 100%;height: 80px;display: flex;align-items: center;justify-content: center;">
                                <div style="width: 140px;height: 78px;background-color: #F1F1F1;display: flex;justify-content: center;align-items: center;"
                                    v-if="scope.row.thumbnailUrl == null">
                                    <p style="font-weight: 400;font-size: 12px;color: #9E9E9E;">加载中</p>
                                </div>
                                <img :src="require('@/static/failToLoad.png')" style="width: 140px;"
                                    v-else-if="scope.row.thumbnailUrl == 'false'" />
                                <img :src="scope.row.thumbnailUrl == null ? require('@/static/theme_3.jpg') :
                                    scope.row.thumbnailUrl == 'false' ? require('@/static/failToLoad.png') : scope.row.thumbnailUrl"
                                    @click="viewImg(scope.row)" style="width: 140px;" v-else />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="支持地区" prop="regionAllowed" show-overflow-tooltip />
                    <el-table-column label="播放时长" prop="duration" />
                    <el-table-column label="清晰度">
                        <template v-slot="scope">
                            {{ scope.row.definition == 'hd' ? '高清' : '标清' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template v-slot="scope">
                            <el-button type="text" @click="open(scope.row)">播放影片</el-button>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

        </div>
        <div v-show="active == 1" style="width:100vw;margin-left:-70px; min-width: 1340px;">
            <div class="bgs" :style="{ height: showTip ? 'calc(100vh - 180px)' : 'calc(100vh - 100px)' }">
                <img src="@/static/bg_one.png" style="width:100%" />
                <img src="@/static/bg_two.png" style="width:100%" />
                <img src="@/static/bg_three.png" style="width:100%" />
                <img src="@/static/bg_four.png" style="width:100%" />
                <img src="@/static/bg_five.png" style="width:100%" />
            </div>
            <div style=" position: absolute;bottom: 0px;min-width:1340px;z-index: 9999;width: 100vw;">
                <p class="agree">
                    <span style="cursor: pointer;" @click="goPri('https://ytplayer.ikukan.com/xy.html')"> 隐私政策 </span>
                    <span style="color:#333">-</span>
                    <span style="cursor: pointer;" @click="goPri('https://ytplayer.ikukan.com/fw.html')"> 服务条款 </span>
                </p>
                <div class="tip" v-show="showTip" style="min-width:1200px;">
                    <i class="el-icon-close" @click="showTip = false"
                        style="position: absolute;top:10px;right:10px;font-size:18px;font-weight: 700;cursor: pointer;" />
                    <div class="tip_con">
                        <span>点击“同意”，即表示您同意我们使用Readonly在影片播放中验证您的 Youtube 账号，通过 Youtube 账号观看购买后的完整影片。</span>
                        <div @click="showTip = false">同意</div>
                        <div @click="showTip = false">拒绝</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            active: 1,
            title: "",
            list: [{
                definition: "hd",
                duration: "02:36:56",
                regionAllowed: "中国香港",
                thumbnailUrl: "https://kukantest.oss-cn-beijing.aliyuncs.com//youtube_img/20240808/1db44a51-d0a4-46d4-8e7a-eb599c646a2a.jpg",
                title: "Spider-Man: No Way Home (Extended Version)",
                videoId: "M0NcVoVVi_I",
            }, {
                definition: "hd",
                duration: "01:43:52",
                regionAllowed: "中国香港,中国台湾",
                thumbnailUrl: "https://kukantest.oss-cn-beijing.aliyuncs.com//youtube_img/20240808/57f3dd6e-e272-4ae9-b377-44ec490e23be.jpg",
                title: "馬哥波羅",
                videoId: "_7FxXCdi2Fs",
            }, {
                title: "再見亦是狗朋友",
                videoId: "gRV9kF3LNM0",
                definition: "hd",
                regionAllowed: "中国香港",
                thumbnailUrl: "https://kukantest.oss-cn-beijing.aliyuncs.com//youtube_img/20240813/dd6fb50f-4cc2-4e0c-b17a-9a50cba66df9.jpg",
                duration: "01:40:15"
            }, {
                title: "解憂雜貨店",
                videoId: "lUgByH_qhFA",
                definition: "hd",
                regionAllowed: "中国香港,中国台湾",
                thumbnailUrl: "https://kukantest.oss-cn-beijing.aliyuncs.com//youtube_img/20240813/7620c859-a44b-4351-a98a-bea6e34ef0ce.jpg",
                duration: "01:49:58"
            }, {
                title: "家有囍事2009",
                videoId: "7qzW8aqmtN4",
                definition: "hd",
                regionAllowed: "中国香港,中国台湾",
                thumbnailUrl: "https://kukantest.oss-cn-beijing.aliyuncs.com//youtube_img/20240813/62802dfb-f079-438a-aebe-664e750722f7.jpg",
                duration: "01:39:43"
            }, {
                title: "哥斯拉：噬星者",
                videoId: "ACBEECie_Pk", definition: "hd", regionAllowed: "中国香港",
                thumbnailUrl: "https://kukantest.oss-cn-beijing.aliyuncs.com//youtube_img/20240823/52e668d1-a00c-4c06-895e-96d7904da3a0.jpg",
                duration: "01:30:08"
            }, {
                title: "流浪地球2",
                videoId: "Av4s4XuDA3c",
                definition: "hd",
                regionAllowed: "中国香港",
                thumbnailUrl: "https://kukantest.oss-cn-beijing.aliyuncs.com//youtube_img/20240809/40645ac0-2221-4488-a527-8a228dce8a3d.jpg",
                duration: "02:53:07"
            }],
            showTip: true
        }
    },
    methods: {
        init() { },
        goPri(url) {
            window.open(url, '_blank')
        },
        open(row) {
            let timeArr = row.duration.split(':')
            let duration = timeArr[0] * 60 * 60 + timeArr[1] * 60 + timeArr[2] * 1
            let data = {
                videoId: row.videoId,
                bgUrl: row.thumbnailUrl,
                title: row.title,
                duration: duration,
                language: 'zh-CN',
                loginStatus: true,
                list: true,
            }
            sessionStorage.setItem('info', JSON.stringify(data))
            this.$emit('goBack', 'index', 1)

        },
        revokeAccess() {
            console.log(888)
            let data = JSON.parse(localStorage.getItem("oauth2-test-params"));
            if (data?.access_token) {
                // Google's OAuth 2.0 endpoint for revoking access tokens.
                var revokeTokenEndpoint = "https://oauth2.googleapis.com/revoke";

                // Create <form> element to use to POST data to the OAuth 2.0 endpoint.
                var form = document.createElement("form");
                form.setAttribute("method", "post");
                form.setAttribute("action", revokeTokenEndpoint);

                // Add access token to the form so it is set as value of 'token' parameter.
                // This corresponds to the sample curl request, where the URL is:
                //      https://oauth2.googleapis.com/revoke?token={token}
                var tokenField = document.createElement("input");
                tokenField.setAttribute("type", "hidden");
                tokenField.setAttribute("name", "token");
                tokenField.setAttribute("value", data.access_token);
                form.appendChild(tokenField);
                // Add form to page and submit it to actually revoke the token.
                document.body.appendChild(form);
                form.submit();
            }
            localStorage.removeItem("oauth2-test-params");
            localStorage.removeItem("state");
        },
    }
}
</script>
<style lang="scss" scoped>
.list_con {
    padding: 0px 70px 0;
    min-width: 1200px;

    .search {
        text-align: center;
        margin-bottom: 20px;

        .el-input {
            width: 500px;
        }
    }
}

.bgs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.agree {
    width: 100%;
    height: 40px;
    line-height: 30px;
    background-color: #fff;
    margin: 0px;
    text-align: center;
    color: #409EFF;
    padding: 0px;
}

.tip {
    background-color: #409EFF;
    height: 80px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    position: relative;

    .tip_con {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        div {
            border: 1px solid #fff;
            padding: 2px 15px;
            border-radius: 5px;
            margin-left: 20px;
            cursor: pointer;
        }
    }
}
</style>