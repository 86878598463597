import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import api from "./utils/api";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

// 全局方法挂载
Vue.prototype.$api = api;
Vue.config.productionTip = false;
Vue.use(ElementUI);

// 获取package.json中version，检测到版本变动，清除浏览器缓存
const VUE_APP_VERSION = require("../package.json").version;
const vers = window.localStorage.getItem("appVersion") || "";
if (VUE_APP_VERSION != vers) {
  localStorage.clear();
  window.localStorage.setItem("appVersion", VUE_APP_VERSION);
  location.reload();
}

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
