const TokenKey = "KK-deskPublicLogin-TOKEN";
import { Message } from "element-ui";

// 获取当前网页的协议+域名
export function getBaseUrl() {
  let baseURL = "";
  if (!window.location.origin) {
    // 兼容IE，IE11版本下location.origin为undefined
    window.location.origin =
      window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port ? ":" + window.location.port + "/" : "");
  } else {
    baseURL = window.location.origin + "/";
  }

  return baseURL;
}
