<template>
    <div class="index" :style="{ padding: type == 'index' ? '0' : '20px 70px' }">
        <div class="back" @click="Back" v-if="type == 'list'">
            <span @click="Back" style="cursor: pointer;" v-if="value?.list">
                << 返回 </span>
        </div>
        <div id="player" ref="player" />
        <!-- 快进、快退、暂停弹窗 -->
        <div class="content dealCon" v-if="keyShow"
            :style="{ width: srcVideo == 'pause' ? '187px' : '173px', height: srcVideo == 'pause' ? '167px' : '127px' }">
            <img v-if="srcVideo" :src="require(`@/static/${srcVideo}.png`)" height="53px"
                :style="{ marginBottom: srcVideo == 'pause' ? '23px' : '4px' }" />
            <p>{{ `${timeArr[0]} / ${timeArr[1]}` }}</p>
        </div>
        <!-- 购买弹窗 -->
        <div class="content codeCon" v-show="codeShow">
            <div class="bg" :style="{ backgroundImage: `url(${value.bgUrl})` }"></div>
            <img v-if="value?.bgUrl" :src="value.bgUrl" height="210px" width="373px" />
            <div class="code_bot">
                <div class="code">
                    <div id="qrCode" ref="qrCodeDiv"></div>
                </div>
                <div class="text">
                    <p class="title">{{ value.title }}</p>
                    <p class="buy">{{ nameArr[langInd] }}</p>
                </div>
            </div>
        </div>
        <!-- 暂停背景图和名称 -->
        <div class="videoCon" :style="{ backgroundImage: `url(${value.bgUrl})`, }" v-show="bgShow">
            <p class="video_title">{{ value?.title }}</p>
        </div>
        <!-- <div style="margin-top:50px">
            <el-button type="primary" @click="dealStatus(23)">播放暂停</el-button>
            <el-button type="primary" @click="dealStatus(22)">快进</el-button>
            <el-button type="primary" @click="dealStatus(21)">快退</el-button>
            <el-button type="primary" @click="dealStatus(4)">返回</el-button>
            <el-button type="primary" @click="dealStatus(5)">bg</el-button>
        </div> -->
        <div class="mask" :style="{ backgroundColor: codeShow ? 'rgba(21,25,32,0.95)' : '' }" v-show="codeShow">
        </div>
        <div class="login" v-if="logNum > 0">
            <span>{{ logArr[langInd] }}</span>
            <span style="margin-left:33px;color: #14CCFF;">{{ logNum }}</span>
        </div>
        <p v-if="codeTip" class="codeTip">{{ codeArr[langInd] }}</p>
    </div>
</template>
<script>
import QRCode from 'qrcodejs2';
export default {
    data() {
        return {
            player: null,// 播放器
            whole: false,// 判断影片是否是全部允许全部播放 false 否  true 是 如果否播放完毕之后弹出购买弹窗
            keyTime: null, // 客户端按键弹窗消失定时器
            status: 1, // 视频播放状态，播放中1 暂停0
            value: {
                // videoId: 'e5Ry6D0ykfA',// M0NcVoVVi_I  D8qVSQLacnc  U6kfyZ_X_uQ MQawI_r9QAA 3WiQRBjzZdE
                // title: 'Harry Potter And The Sorcerer’s Stone: Magical Movie Mode',
                // duration: 5829,
                // bgUrl: 'https://i.ytimg.com/vi/sVmtqsJguEw/hqdefault.jpg',
                // language: 'zh'
            },// 视频内容信息
            urls: ['forward', 'retreat', 'pause'], // 暂停快进、快退、暂停图标名字
            timeArr: ['00:05:23', '02:05:23'], // 视频总时长&当前播放时长
            srcVideo: 'pause', // 暂停快进、快退、暂停显示的对应图标
            keyShow: false, // 暂停快进、快退、暂停弹窗是否显示
            codeShow: false, // 购买弹窗是否显示
            buyText: '',// 购买提示语  
            bgShow: false,// 暂停&购买弹窗背景图是否显示
            showEnd: false,// 快进最后五秒钟，视频进度条不会快进了，再次获取是，进度条停留在小于五秒钟之前，所以需要判断是否快进最后五秒，如果是，再次点击快退时，获取进度条不用-5秒
            langInd: 0,// 多语言对应的语言索引
            errorTip: [],
            nameArr: [
                "扫 码 购 买 或 租 赁 查 看 完 整 版 本",
                "スキャン購入またはリースフルバージョンの表示",
                "코드 스캔 구매 또는 대여 전체 버전 보기",
                "Escanear el Código para comprar o alquilar para ver la versión completa",
                "Scan the code to purchase or rent to view the complete version",
                "Digitalize o código para comprar ou alugar para ver a versão completa",
                "Sweep Code acheter ou louer voir la version complète",
                "掃 碼 購 買 或 租 賃 查 看 完 整 版 本",
                "Imbas kod QR untuk membeli atau menyewa untuk melihat versi penuh",
                "สแกนโค้ด ซื้อหรือเช่า ดูเวอร์ชั่นเต็ม",
            ],
            errorArr: ['当前视频无法播放，请联系客服以获取帮助并解决问题。',
                '現在のビデオは再生できません。ヘルプを入手し、問題を解決するためにカスタマーサービスに連絡してください。',
                '동영상을 재생할 수 없습니다. 고객 서비스에 문의하여 도움을 받으십시오.',
                'El video actual no se puede reproducir. Póngase en contacto con el servicio al cliente para obtener ayuda y resolver el problema.',
                'The video cannot be played, please contact customer service for help and resolution.',
                'O vídeo atual não pode ser reproduzido. Entre em contato com o atendimento ao cliente para obter ajuda e resolver o problema.',
                'La vidéo actuelle ne peut pas être lue. Veuillez contacter le service clientèle pour obtenir de l’aide et résoudre le problème.',
                '當前視頻無法播放，請聯繫客服以獲取幫助並解決問題。',
                'Video semasa tidak boleh dimainkan, sila hubungi perkhidmatan pelanggan untuk mendapatkan bantuan dan menyelesaikan masalah.',
                'ไม่สามารถเล่นวิดีโอปัจจุบันได้โปรดติดต่อฝ่ายบริการลูกค้าเพื่อขอความช่วยเหลือ และแก้ไขปัญหา'
            ],
            endArr: ['播放已结束', '再生終了', '재생이 종료됨', 'La reproducción ha terminado', 'Play has ended', 'A reprodução terminou', 'Le jeu est terminé', '播放已結束', 'Permainan telah berakhir', 'เล่นจบแล้ว'],
            startArr: ['播放未开始', '再生が開始されていません', '재생이 시작되지 않았습니다.', 'La reproducción no ha comenzado', 'The playback has not started yet', 'A reprodução ainda não começou', 'Le jeu ne démarre pas', '播放未開始', 'Main balik belum bermula lagi', 'เล่นยังไม่เริ่ม'],
            logArr: ['稍后立即登录', 'しばらくしてすぐにログインする', '잠시 후에 즉시 로그인하십시오', 'Iniciar sesión inmediatamente después de un momento', 'Log in immediately after a short while', 'Fazer login imediatamente depois de um pouco', 'Connectez-vous immédiatement après un instant', '稍後立即登錄', 'Log masuk segera selepas sebentar', 'เข้าสู่ระบบทันทีหลังจากสักครู่'],
            logNum: 0,// 跳转登录倒计时
            timeLog: null,
            num: 0,
            flagStart: true,
            type: 'index',// 判断从列表进来还是从首页进来
            codeTip: false,//刚进来提示语
            codeArr: [
                '租赁影片请按“菜单键”继续播放，加载中未播放的请按“确定键”播放。',
                'レンタル映像を再生するには「メニューボタン」を押してください。ロード中で再生されていない場合は「決定ボタン」を押してください。',
                "대여 영상을 계속 재생하려면 '메뉴 버튼'을 누르십시오. 로딩 중에 재생되지 않은 경우 '확인 버튼'을 누르십시오.",
                'Para continuar reproduciendo la película alquilada, presione el "botón de menú". Si no se reproduce durante la carga, presione el "botón de confirmación".',
                'To continue playing the rented movie, press the "menu button". If it is loading and not playing, press the "confirm button".',
                'Para continuar reproduzindo o filme alugado, pressione o "botão de menu". Se estiver carregando e não tocando, pressione o "botão de confirmar".',
                `Pour continuer à lire le film loué, appuyez sur le "bouton menu". S'il est en cours de chargement et ne se lit pas, appuyez sur le "bouton de confirmation".`,
                '若要繼續播放租賃影片，請按「菜單鍵」。若載入中未播放，請按「確定鍵」。',
                'Untuk terus memainkan filem sewa, tekan "butang menu". Jika sedang dimuatkan dan tidak dimainkan, tekan "butang sahkan".',
                'หากต้องการเล่นวิดีโอเช่าต่อไป กด "ปุ่มเมนู" หากกำลังโหลดอยู่แต่ไม่เล่น กด "ปุ่มยืนยัน"']
        }
    },
    mounted() {
        window.dealStatus = this.dealStatus
    },
    beforeDestroy() {
        clearInterval(this.timeLog)
        this.player?.destroy()
    },
    methods: {
        init(flag, val) {
            let info = sessionStorage.getItem('info') || {}
            this.value = info?.length > 0 ? JSON.parse(info) : {}
            this.type = this.value?.list ? 'list' : 'index'
            console.log(this.type, info?.list)
            const tag = document.createElement('script')
            tag.src = "https://www.youtube.com/iframe_api";
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            tag.onload = this.onYouTubeIframeAPIReady()
            !flag ? this.multilingual() : ""
            !flag ? this.bindQRCode() : ''
        },
        // 视频渲染
        onYouTubeIframeAPIReady() {
            let that = this
            try {
                let width = this.type == 'index' ? window.innerWidth : 1280
                let height = this.type == 'index' ? window.innerHeight : 720
                window.YT.ready(() => {
                    this.player = new YT.Player('player', {
                        playerVars: {
                            enablejsapi: 1,
                            cc_load_policy: 1,
                            controls: 1,
                            autoplay: 0,
                            rel: 0,
                        },
                        height: height + '',
                        width: width + '',
                        videoId: that.value.videoId,
                        events: {
                            'onReady': this.onPlayerReady,
                            'onStateChange': this.onPlayerStateChange,
                            'onError': this.error
                        }
                    })
                })
                this.codeTip = this.type == 'index' ? true : false
            } catch (error) {
                this.$message.error(this.errorArr[this.langInd] + error);
            }
        },
        // 视频准备好触发
        onPlayerReady(event) {
            // 获取总时长、视频时长，如果一样(允许10s误差)，说明能播放全部影片，如果不一样，则播放完毕之后弹出购买弹窗
            this.flagStart = true
            if (window?.Android) {
                window.Android?.activatePlayer()
            }
        },
        // 视频播放状态变化触发
        onPlayerStateChange(event) {
            event?.data == 1 ? this.codeTip = false : ''
            if (event.data == 0) {
                // 总时长 - 可播放时长 ：是否需要在播放完毕之后弹出购买弹窗，允许20s误差
                let timeDiff = this.value.duration - this.player.getDuration();
                // 视频播放结束，判断该应用是否是全部播放 是-显示暂停弹窗 否-显示扫码购买弹窗
                if (timeDiff < 20) {
                    this.status = this.player.getPlayerState()
                    this.bgShow = true
                    this.getNone(2, true);
                } else {
                    this.keyShow = false
                    // 如果是第一次，则进行多语言判断、二维码渲染
                    // this.bindQRCode()
                    this.bgShow = true
                    this.codeShow = true
                }
                this.player.pauseVideo()
            }
            // 如果是第一次播放，禁用菜单键事件 -- 菜单键主要针对租赁视频
            if (event?.data == 1 && this.codeTip && window?.Android) {
                window.Android?.menuKeyDisabled()
            }
        },
        // 视频渲染报错信息
        error(event) {
            event.data != 150 ? this.$message.error(this.errorArr[this.langInd]) : ''
            console.log(event, event.targe, event?.targe?.i, 'err')
            if (event.data == 150) {
                let num = sessionStorage.getItem('num') || 0
                sessionStorage.setItem('num', num * 1 + 1)
                num < 3 && this.type == 'index' ? this.goLog() : ''
            }
            // 视频报错，返回值给客户端，客户端进行数据埋点统计
            if (window?.Android) {
                if (window.Android?.report) {
                    console.log('report report')
                }
                let msg = '404'
                let code = event.data + ''
                window.Android?.report(msg, code)
            }
            this.codeTip = false
        },
        goLog() {
            this.logNum = 3
            this.timeLog = setInterval(() => {
                this.logNum--
                if (this.logNum == 0) {
                    clearInterval(this.timeLog)
                    localStorage.removeItem('oauth2-test-params')
                    localStorage.removeItem('state')
                    this.$parent.oauth2SignIn()
                }
            }, 1000)
        },

        // 客户端操作事件
        dealStatus(num) {
            this.num = num
            // 23-播放&暂停 22-快进 21-快退 4-返回键  购买弹窗出现时，点击返回键消失，点击其他键不处理
            if (num == 23 && !this.codeShow) {
                this.status = this.player.getPlayerState();
                if (this.status == 1) {
                    this.bgShow = true;
                    if (this.flagStart) {
                        let time = this.player.getCurrentTime()
                        this.player.seekTo(time, true);
                    }
                    this.player.pauseVideo();
                    this.getNone(2);
                } else {
                    this.keyShow = false
                    this.bgShow = false;
                    //播放结束之后，先跳转一下再播放，不然需要点击两下才能播放，其他情况下只需要直接播放即可
                    this.status == 0 ? this.player.seekTo(0, true) : ''
                    this.player.playVideo();
                }
                this.status = this.status == 1 ? 2 : 1;
                this.showEnd = false
                this.flagStart = false
                return true;
            } else if (num == 22 && !this.codeShow) {
                this.dealCode(0);
                return true;
            } else if (num == 21 && !this.codeShow) {
                this.dealCode(1);
                return true;
            } else if (num == 4) {
                // 返回键两种情况：支付弹窗出现，返回键关闭弹窗；返回键没出现，返回键返回上级页面
                // 只有在播放页面按照返回键才能重新渲染视频，其他页面点击返回键不处理
                if (this.codeShow) {
                    this.bgShow = false
                    this.codeShow = false
                    this.player.destroy()
                    this.init(true)
                    return true;
                } else {
                    // 如果按了返回键，同时支付弹窗没有出现，则返给客户端值，告诉他可以返回到影片列表页
                    if (window?.Android) {
                        window.Android?.onPlayerBack()
                    }
                    return false
                }
            } else if (num == 5) {
                this.codeShow = true
            }
        },
        // 处理视频快进、快退
        dealCode(flag) {
            // flag true 快进 false 后退
            let time = this.player.getCurrentTime();
            let total = this.player.getDuration();
            let times = 0;
            let len = total - time;
            if (flag == 0) {
                times = len <= 5 ? total : time + 5;
            } else {
                times = time < 5 ? 0 : this.showEnd ? time : time - 5;
            }
            this.getNone(flag);
            if (!this.showEnd) {
                let state = this.player.getPlayerState();
                this.player.seekTo(times, true);
                state == 1 && times != total ? this.player.playVideo() : state == 2 ? this.player.pauseVideo() : ''
            }

            if (flag == 0 && times == total && this.showEnd) {
                this.$message.warning(this.endArr[this.langInd])
            } else if (flag == 1 && time == 0) {
                this.$message.warning(this.startArr[this.langInd])
            }
            this.showEnd = len <= 5 && flag == 0 ? true : false
        },
        // 关闭快进弹窗
        getNone(num, sign,) {
            // 快进、快退获取播放时长时，进行处理，因为获取的不是快进快退的时长，所以要进行加减获取准确播放时长，暂停不用处理
            let da = this.player.getCurrentTime()
            let total = this.player.getDuration()
            let time = num == 0 ? da + 5 : num == 1 && !this.showEnd ? da - 5 : num == 2 && sign ? da + 5 : da;
            let times = time < 0 ? 0 : time > total ? total : time;
            let start = this.getTime(times);
            let end = this.getTime(total);
            this.timeArr.splice(0, 1, start)
            this.timeArr.splice(1, 1, end)
            this.srcVideo = this.urls[num];
            this.keyShow = true
            clearTimeout(this.keyTime);
            this.status = this.player.getPlayerState();
            // 如果视频为播放状态1，弹窗消失 如果为暂停状态0，经过一定时间转变为暂停状态弹窗
            this.keyTime = setTimeout(() => {
                let arr = [1, 3, -1]
                arr.includes(this.status) ? (this.keyShow = false) : "";
                !arr.includes(this.status) ? (this.srcVideo = this.urls[2]) : "";
            }, 1200);
        },
        // 将时间秒转化为时分秒
        getTime(value) {
            let second = parseInt((value) % 60);
            let minute = parseInt((value / 60) % 60);
            let hour = parseInt((value / 3600) % 24);
            let day = parseInt((value / 3600 / 24))
            let result = "";
            if (day > 0) {
                result = day >= 10 ? day : "0" + day;
            }
            result += " " + (hour >= 10 ? hour : "0" + hour);
            result += ":" + (minute >= 10 ? minute : "0" + minute);
            result += ":" + (second >= 10 ? second : "0" + second);
            return result;
        },
        // 多语言处理
        multilingual() {
            let lang = this.value.language
            let langArr = lang?.includes("-") ? lang.split("-") : [lang]
            // 根据当前语言，找出对应语言的索引
            const arr = ["zh-CN", "ja", "ko", "es", "en", "pt", "fr", "zh-TW", "ms", "th",];
            if (langArr?.length > 1) {
                // 中文简体字&繁体字的单独处理
                if (langArr[0] == 'zh') {
                    this.langInd = langArr[1] == 'TW' ? 7 : 0
                } else {
                    this.langInd = arr.indexOf(langArr[0])
                }
            } else {
                this.langInd = langArr[0] == 'zh' ? 0 : arr.indexOf(langArr[0])
            }
            this.langInd = this.langInd == -1 ? 4 : this.langInd

        },
        // 生成二维码
        bindQRCode: function () {
            let that = this
            new QRCode(this.$refs.qrCodeDiv, {
                text: `https://www.youtube.com/watch?v=${that.value.videoId}`,
                width: 126,
                height: 126,
                colorDark: "#333333", //二维码颜色
                colorLight: "#ffffff", //二维码背景色
                correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
            })
            // this.$refs.qrCodeDiv.title = ''
        },
        Back() {
            this.$emit('goBack', 'list')
        }
    }
}
</script>
<style lang="scss" scoped>
.index {
    position: relative;
    width: 100vw;
    height: 100vh;
    font-family: Source Han Sans, Source Han Sans;
    font-style: normal;
    color: #fff;
    font-size: 16px;
    margin: 0;
    padding: 0;

    .back {
        color: #333;
        margin-bottom: 10px;
        color: #409EFF;
    }

    p {
        margin: 0px
    }

    .codeTip {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 8888;
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        border-radius: 10px;
        font-size: 20px;
        font-weight: 400;
        padding: 10px;
    }

    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99999;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .dealCon {
        background: rgba(0, 0, 0, 0.7);
    }

    .codeCon {
        width: 373px;
        background: #2C4467;
        padding: 27px;
        overflow-y: hidden;


        .code_bot {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 13px;

            .code {
                width: 147px;
                height: 147px;
                background: #FFFFFF;
                border-radius: 11px;
                display: flex;
                align-items: center;
                justify-content: center;

            }

            .text {
                text-align: center;
                width: 214px;

                .title {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    line-height: 23px;
                }

                .buy {
                    font-size: 14px;
                    padding: 5px 13px;
                    background: rgba(255, 255, 255, 0.1);
                    border-radius: 11px;
                    margin-top: 3px;
                    color: rgba(255, 255, 255, 0.8);
                    height: 64px;
                    display: flex;
                    align-items: center;
                    line-height: 21px;
                }
            }
        }
    }

    .videoCon {
        position: absolute;
        top: 0px;
        left: 0px;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 999;
        width: 100%;
        min-height: 100%;


        .video_title {
            width: calc(100% - 30px);
            height: 67px;
            background: rgba(0, 0, 0, 0.6);
            border-radius: 0px;
            font-size: 22px;
            line-height: 67px;
            padding-left: 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .mask {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 999;
    }

    .login {
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        padding: 15px 25px;
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        border-radius: 10px;
        font-size: 20px;
        font-weight: 400;
        z-index: 999;
    }
}
</style>